import React, { useEffect, useState } from "react"
import seoAnalysis from "../../assets/images/seo-analysis.png"

import repo from "../../repository/api_repository"

import { Spinner } from "react-bootstrap"
import { getUserDomain, isMultiWebSite } from "../../utils/inv-id"
import ResultModal from "./ResultModal"

const BusinessPartnerForm = props => {
  const successMessage = "Form başarıyla gönderilmiştir."

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(successMessage)

  const addBitrixCallmeForm = () => {
    const inner = `(function(w,d,u){
      var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
      })(window,document,'https://cdn.bitrix24.com.tr/b27663209/crm/form/loader_8.js');`

    const script = document.createElement("script")

    script.setAttribute("data-b24-form", "inline/8/ngmdnp")
    script.setAttribute("data-skip-moving", "true")

    script.innerHTML = inner
    document.getElementById("bitrix-call-me-form").appendChild(script)
  }

  const oldForm = () => {
    return (
      <form
        onSubmit={async e => {
          e.preventDefault()

          let invId = parseInt(localStorage.getItem("r"))
          setIsLoading(true)
          if (isMultiWebSite()) {
            const json = await repo.domainToInvestorId(getUserDomain())
            console.log(json)
            const idFromApi = json.cats?.id
            invId = idFromApi
          }

          const name = e.target.name.value

          let phone = e.target.phone.value

          if (phone.length === 10) {
            phone = "0" + phone
          }

          if (isNaN(invId)) {
            invId = 0
          }

          const json = await repo.sendContactForm(invId, name, phone, "")
          console.log(json)
          setIsLoading(false)
          if (json.status === 404) {
            setMessage(json.message)
            setIsSuccess(false)
            setShow(true)
            return
          }
          setMessage(successMessage)
          setIsSuccess(true)
          setShow(true)
        }}
      >
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="İsim Soyisim"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="Telefon No."
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <button type="submit" className="default-btn">
            <i className="flaticon-tick"></i>
            Gönder
            <span></span>
          </button>
        )}
      </form>
    )
  }

  useEffect(() => {
    addBitrixCallmeForm()
  }, [])

  return (
    <div className="seo-analysis-section pb-100" ref={props.formRef}>
      <ResultModal
        show={show}
        onHide={() => setShow(false)}
        message={message}
        isSuccess={isSuccess}
      />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-img">
              <img src={seoAnalysis} alt="analysis" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="analysis-form">
              {/* <span className="sub-title">Analysis</span> */}
              <h2>
                Formu doldur
                <br />
                seni arayalım
              </h2>
              <p>
                Alanında uzman arkadaşlarımız seni arasın ve aklına takılan tüm
                soruları cevaplasın. Dilersen sende haftanın 7 Günü Saat
                09.00-19:00 arası 0(212) 651 20 20 numaralı müşteri
                hizmetlerinden destek alabilirsin.
              </p>

              <div id="bitrix-call-me-form"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessPartnerForm
