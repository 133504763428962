const { token } = require("../utils/api-values")

const baseUrl = "https://alisverislio.com"
// const baseUrl = "http://magaza.alisverislio.org"

const invIdToCustomerId = async invId => {
  var url = new URL(baseUrl)
  url.pathname = "/api/customers/getcustomerid"
  url.searchParams.append("invId", invId)

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  })

  return response
}

const buyProPacketMoneyOrder = async (customerId, startedpacket) => {
  var url = new URL(baseUrl)

  url.pathname = "/api/customers/register/moneyorder"
  url.searchParams.append("customerId", customerId)
  url.searchParams.append("startedpacket", startedpacket)

  var response = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  })

  return response
}

const getFooter = async domain => {
  var url = new URL(baseUrl)
  url.pathname = "/api/DomainMainPage"

  url.searchParams.append("domain", domain)

  var response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  })

  var json = await response.json()

  return json
}

const sendContactForm = async (invId, name, phone, message) => {
  var url = new URL(baseUrl)

  url.pathname = "/api/v2/panelmanagement/contactformlist"

  if (phone.length === 10) {
    phone = "0" + phone
  }
  url.searchParams.append("referansLinkId", invId)
  url.searchParams.append("name", name)
  url.searchParams.append("gsm", phone)
  url.searchParams.append("message", message)
  var response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const json = await response.json()

  return json
}

const domainToInvestorId = async domain => {
  var url = new URL(baseUrl)

  url.pathname = "/api/GetWInvestorByDomain"

  url.searchParams.append("domain", domain)

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  })

  const json = await response.json()

  return json
}

const getProPacketCreditCardPaymentURL = (
  cardNo,
  name,
  cvv,
  month,
  year,
  customerId,
  packetAmount,
  packetName
) => {
  var payUrl = new URL(
    "https://panel.alisverislio.com/Payment/VakifKatilimPaymentForApi"
  )

  payUrl.searchParams.append("CardId", "0")
  payUrl.searchParams.append("CardHolderName", name)
  payUrl.searchParams.append("CardNo", cardNo)
  payUrl.searchParams.append("CardMonth", month)
  payUrl.searchParams.append("CardYear", year)
  payUrl.searchParams.append("CardCVV", cvv)
  payUrl.searchParams.append("PaymentType", "propaket")
  payUrl.searchParams.append("CustomerId", customerId)
  payUrl.searchParams.append("Amount", packetAmount)
  payUrl.searchParams.append("Area1", "0")
  payUrl.searchParams.append("Area2", packetName)

  return payUrl
}

module.exports = {
  invIdToCustomerId,
  buyProPacketMoneyOrder,
  getProPacketCreditCardPaymentURL,
  getFooter,
  domainToInvestorId,
  sendContactForm,
}
