import { Link } from "gatsby"
import React from "react"
import callMe from "../../assets/images/call_me.jpeg"
import registerBanner from "../../assets/images/register_banner.jpeg"

const InvestorButtons = props => {
  const onScroll = props.onScroll
  return (
    <section>
      <div className="container">
        <div className="row pb-5">
          <div className="col">
            <img src={callMe} onClick={onScroll} />
          </div>
          <div className="col">
            <Link to="/register">
              <img src={registerBanner} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InvestorButtons
