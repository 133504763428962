import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/App/Layout"

import Navbar from "../components/App/Navbar"

import VideoComments from "../components/App/VideoComments"

import BusinessPartnerForm from "../components/App/BusinessPartnerForm"

import AboutUs from "../components/HowItWorks/AboutUs"
import FeaturedServices from "../components/HowItWorks/FeaturedServices"
import InvestorButtons from "../components/BecomeInvestor/InvestorButtons"
import GainImage from "../components/BecomeInvestor/GainImage"
import Footer from "../components/App/Footer"

const BecomeInvestor = props => {
  const ref = useRef()
  return (
    <Layout>
      <Navbar />
      {/* <WhatWeDo /> */}
      {/* <Services /> */}
      {/* <CaseStudy /> */}
      {/* <ListWithNumber /> */}
      <AboutUs />
      <FeaturedServices />
      <GainImage />
      {/* <WhoAlisverislio /> */}
      <BusinessPartnerForm formRef={ref} />

      <VideoComments />
      <InvestorButtons
        onScroll={() => {
          ref.current?.scrollIntoView()
        }}
      />
      {/* <Packets /> */}
      <Footer />
    </Layout>
  )
}

export default BecomeInvestor
