import React from "react"

import gain1 from "../../assets/images/become-investor/gain-image/gain-image-1.png"
import gain2 from "../../assets/images/become-investor/gain-image/gain-image-2.png"

export default function GainImage() {
  return (
    <section className="container pb-3">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <img src={gain1} />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <img src={gain2} />
        </div>
      </div>
    </section>
  )
}
