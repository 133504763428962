const saveId = id => {
  localStorage.setItem("r", id)
}

const getId = () => {
  return parseInt(localStorage.getItem("r"))
}

const getRefIdFromParameters = props => {
  var href = props.location.href

  const url = new URL(href)

  const args = new URLSearchParams(url.search)

  return args.get("r")
}

const getUserDomain = () => {
  var url = new URL(window.location.href)

  var host = url.host
  // http://www.oguzhan.alisverislio.com
  const parts = host.split(".")

  return parts[0]?.trim()
}

const isMultiWebSite = () => {
  var domain = getUserDomain()
  console.log(domain)
  if (domain == "nedir" || domain == 'localhost:8000') {
    return false
  }

  return true
  // console.log(domain)
  // return domain != "kargolio" && domain != "www"
}

module.exports = {
  saveId,
  getId,
  getRefIdFromParameters,
  getUserDomain,
  isMultiWebSite,
}
