import React, { useEffect, useState } from "react"

import client1 from "../../assets/images/testimonials/client1.jpg"

import shape from "../../assets/images/shape/shape1.svg"
import Loadable from "@loadable/component"

import { token } from "../../utils/api-values"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
}

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false)
  const [items, setItems] = useState([])
  useEffect(() => {
    var url = new URL("https://alisverislio.com/api/VideoOpinions")
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(data => data.json())
      .then(json => {
        if (json.status != 404) {
          setItems(json.cats)
        }
      })
  }, [])

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="testimonials-area bg-f1f8fb">
      <div className="container">
        <div className="section-title pt-4">
          {/* <span className="sub-title">
            <img src={starIcon} alt="testimonial" />
            Testimonials
          </span> */}
          <h2>Neden Alışverişlio?</h2>
          <p>
            Harcarken kazanmayı tercih etmiş yüz binlerce tüketici
            alışverişlio'yu keyifle öneriyor.
          </p>
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            {items.map(item => VideoCommentItem(item))}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      <div className="shape-img1">
        <img src={shape} alt="testimonial" />
      </div>
    </div>
  )
}

const VideoCommentItem = item => {
  var link = item.Link
  var url = new URL(link)

  var params = new URLSearchParams(url.search)

  var videoId = params.get("v")

  var src = `https://www.youtube.com/embed/${videoId}`

  return (
    <div className="single-testimonials-item">
      <p>{item.City}</p>
      <div>
        <iframe
          src={src}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>

      <div className="client-info">
        <div className="d-flex justify-content-center align-items-center">
          <img src={client1} alt="testimonial" />
          <div className="title">
            <h3>{item.name}</h3>
            <span>{item.Description}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
